import React from "react";
import styled, { css } from "styled-components";
import { Dayjs } from "dayjs";
import { useGate, useStore } from "effector-react";

import { MetaTags } from "shared/lib/metaTags";
import { MailIcon, PhoneIcon, YachtLengthIcon } from "shared/ui/icons";
import { convertHoursFromArrayToMap } from "entities/docks";
import { daysKeys } from "entities/docks/lib/operational-hours";
import { displayTime } from "shared/lib/dayjs-ext";
import {
  geDockFullAddress,
  getDockShortAddress,
} from "entities/docks/lib/adress";
import { PhoneNumber } from "shared/ui/phone-number";
import { MapWithMarker } from "shared/ui/map";
import { mediaDesktop, mediaPhone } from "shared/ui/styles/media";
import { ReserveDockForm } from "@client-app/features/reserv-dock";
import { SuccessModal } from "@client-app/entities/reservation";

import { $dock, dockPageGate } from "./model";

export function DockListByIdPage(): JSX.Element {
  useGate(dockPageGate);
  const dock = useStore($dock);

  if (!dock) return <></>;

  const dockCoordinates =
    dock.latitude && dock.longitude
      ? {
          lat: parseFloat(dock.latitude),
          lng: parseFloat(dock.longitude),
        }
      : null;

  const operationalHoursMap = convertHoursFromArrayToMap(
    // @ts-ignore
    dock.operationalHours ?? []
  );
  const currentDay = new Date().getDay();

  return (
    <Root>
      <MetaTags title={`Dock ${dock.name}`} />
      <DockImage src={dock.image.url ?? ""} alt="dock image" />
      <Content>
        <Info>
          <Header>
            <DockName>{dock.name}</DockName>

            <HeaderInfo>
              <Address>{getDockShortAddress(dock)}</Address>
              <Contacts>
                {dock.email && (
                  <Contact>
                    <ContactIcon as={MailIcon} />
                    <a href={`mailto:${dock.email}`}>{dock.email}</a>
                  </Contact>
                )}
                <Contact>
                  <ContactIcon as={PhoneIcon} />
                  <a href={`tel:${dock.phone}`}>
                    <PhoneNumber phone={dock.phone} />
                  </a>
                </Contact>
              </Contacts>
            </HeaderInfo>
          </Header>

          {dock.description ? (
            <InfoBlock title="Description">
              <Description
                dangerouslySetInnerHTML={{ __html: dock.description }}
              ></Description>
            </InfoBlock>
          ) : null}

          <InfoBlock title="Features">
            <Features>
              <Feature>
                <FeatureIcon as={YachtLengthIcon} />
                {dock.length}ft dock length
              </Feature>
            </Features>
          </InfoBlock>
          {dock?.restaurants && dock.restaurants?.length > 0 && (
            <InfoBlock title="Restaurants nearby">
              <Restaurants>
                {dock.restaurants.map((restaurant) => (
                  <Restaurant key={restaurant["@id"]!}>
                    <RestaurantType>{restaurant["@type"]} — </RestaurantType>
                    <RestaurantName
                      as={restaurant.url! && "a"}
                      href={restaurant.url!}
                      target="_blank"
                    >
                      {restaurant.name}
                    </RestaurantName>
                    <Distance>{restaurant.distance}mi</Distance>
                  </Restaurant>
                ))}
              </Restaurants>
            </InfoBlock>
          )}
          <InfoBlock title="Operational hours">
            <OperationalHours>
              {daysKeys.map((day, index) => (
                <React.Fragment key={day}>
                  <Day current={currentDay === index}>{day}</Day>
                  <Hours current={currentDay === index}>
                    {operationalHoursMap[day] ? (
                      <TimeRange
                        from={operationalHoursMap[day].from}
                        to={operationalHoursMap[day].to}
                      />
                    ) : (
                      <CLoseLabel>Closed</CLoseLabel>
                    )}
                  </Hours>
                </React.Fragment>
              ))}
            </OperationalHours>
          </InfoBlock>
          <InfoBlock title="Dock location">
            <Location>
              <Address>{geDockFullAddress(dock)}</Address>
              {dockCoordinates && (
                <Map>
                  <MapWithMarker coords={dockCoordinates} />
                </Map>
              )}
            </Location>
          </InfoBlock>
        </Info>
        <ReservationBlock>
          <ReserveDockForm />
        </ReservationBlock>
      </Content>

      <SuccessModal dock={dock} />
    </Root>
  );
}

const WeekDay = styled.div<{ current: boolean }>`
  ${(props) =>
    props.current &&
    css`
      color: #5e88ef;
    `}
`;

const Hours = styled(WeekDay)``;

const Day = styled(WeekDay)``;

function TimeRange(props: { from: Dayjs; to: Dayjs }): JSX.Element {
  return (
    <TimeRangeRoot>
      <div>{displayTime(props.from)}</div> - <div>{displayTime(props.to)}</div>
    </TimeRangeRoot>
  );
}

const TimeRangeRoot = styled.div`
  display: flex;
  gap: 2px;
`;

const CLoseLabel = styled.div`
  color: #969899;
`;

const OperationalHours = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
`;

const Restaurants = styled.div`
  display: inline-grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
`;

const DockImage = styled.img`
  ${mediaDesktop} {
    width: 100%;
    border-radius: 32px;
  }

  ${mediaPhone} {
    width: auto;
    max-width: 100vw;
    margin: 0 calc(var(--pageContentPadding, 16px) * -1);
    border-radius: 0;
  }
`;

const DockName = styled.div`
  font-family: "Kanit", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 48px;
`;

const Address = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: #969899;
`;

const ContactIcon = styled.div`
  width: 24px;
  height: 24px;
`;

const Contact = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  &,
  a {
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    text-decoration-line: underline;
    color: #2d4fa1;
  }
`;

const Contacts = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 12px;
`;

const HeaderInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${mediaPhone} {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
`;

const Header = styled.div`
  padding-bottom: 32px;
`;

const InfoBlock = styled(
  (props: { title: string; children: React.ReactNode; main?: boolean }) => (
    <InfoBlockRoot>
      {props.title && <InfoTitle>{props.title}</InfoTitle>}
      <InfoContent>{props.children}</InfoContent>
    </InfoBlockRoot>
  )
)``;

const InfoTitle = styled.div`
  font-family: "Kanit", sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
`;

const InfoContent = styled.div``;

const InfoBlockRoot = styled.div`
  border-top: 1px solid #e7ebf7;
  padding: 24px 0 32px;

  ${InfoTitle} {
    margin-bottom: 16px;
  }
`;

const Description = styled.div`
  p {
    margin: 8px 0 8px;
  }
`;

const FeatureIcon = styled.div`
  width: 40px;
  height: 40px;
`;

const Feature = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  font-size: 18px;
  line-height: 29px;
  padding: 0 24px;

  ${FeatureIcon} {
    margin-bottom: 8px;
  }
`;

const Features = styled.div`
  display: flex;
`;

const RestaurantType = styled.div`
  color: #969899;
`;

const RestaurantName = styled.div`
  color: #0a1128;
`;

const Distance = styled.div`
  text-align: right;
`;

const Restaurant = styled.div`
  display: inline-flex;
  grid-gap: 10px;
  min-width: 410px;

  ${Distance} {
    flex: 1;
  }

  ${mediaPhone} {
    min-width: auto;
    width: 100%;
  }
`;

const Info = styled.div``;

const Map = styled.div`
  max-width: 100%;
  height: 410px;
  border-radius: 24px;
  overflow: hidden;

  ${mediaPhone} {
    max-width: 100vw;
    margin: 0 calc(var(--pageContentPadding) * -1);
    border-radius: 0;
  }
`;

const Location = styled.div`
  ${Address} {
    margin-bottom: 4px;
  }
`;

const ReservationBlock = styled.div`
  background: #f7f9fc;
  box-shadow: 0 0 24px rgba(39, 50, 60, 0.12);
  border-radius: 24px;
  padding: 32px 24px;
`;

const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 380px;
  align-items: start;
  gap: 20px;

  ${Info} {
    flex: 1;
  }

  ${mediaPhone} {
    grid-template-columns: 1fr;
  }
`;

const Root = styled.div`
  ${Content} {
    margin-top: 24px;
  }
`;
